<template>
  <CRow>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>

    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <div style="display: flex; justify-content:space-between; align-items:center;">
              <CCardHeader>
                Usuarios (Usuarios Aplicación)
                <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="openModalCreateUser"
                >
                  Crear Usuario
                </CButton>
              </CCardHeader>
              <div style="display: flex; align-items: center; gap: 12px;">

                <CButton style="display:flex; margin-left:auto; margin-right: 20px;" color="primary" @click="modalExport = true;">Descargar Usuarios</CButton>
                <CButton color="primary" @click="modalNotification = !modalNotification">Enviar Notificación</CButton>
              <div style="padding-top: 8px;">
                <p style="margin-right:12px; margin-bottom:0;">Total de Usuarios: {{total}}</p>
                <p v-if="users_installed_app_count" style="margin-right:12px; margin-bottom:0;">Usuarios con App Instalada: {{users_installed_app_count}}</p>
                <p v-if="users_with_company_count" style="margin-right:12px; margin-bottom:0;">Usuarios con Empresa: {{users_with_company_count}}</p>
              </div>
              </div>
              </div>
              <CCardBody>
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="items"
                  :fields="fields"
                  :items-per-page="pagination.perPage"
                  :pagination="pagination"
                  index-column
                  clickable-rows
                >
                <template #item="data"> 
                  <td>{{data.created_at}}</td> 
                </template>
                  <template #role="data">
                    <td>{{data.role}}</td>
                  </template>
                  <template #company="data">
                    <td>{{data?.companyName}}</td>
                  </template>
                  <template #devices="data">
                    <td>{{data?.devices_count}}</td>
                  </template>
                  <template #actions="data">
                    <td>
                        <CButton
                        v-c-tooltip="'Editar datos de usuario'"
                        color="warning"
                        size="sm"
                        class="m-1"
                        @click="updateUser(data.item, $event)"
                      >
                        <font-awesome-icon icon="user-edit" />
                      </CButton>
                      <CButton
                        v-c-tooltip="'Eliminar usuario'"
                        color="red"
                        size="sm"
                        class="m-1"
                        @click="openDeleteModal(data.item, $event)"
            
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              <div style="width: 200px; margin: 0 auto;">
              <CPagination
              :active-page.sync="currentPage"
              :pages="Math.ceil(total / pagination.perPage)"
              align="center"
            />
            </div>
                <div 
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
                  <CSpinner
                    style="width:4rem;height:4rem;"
                    color="danger"
                  />
                  <p class="text-secondary my-2">Cargando datos...</p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
<!--           <CCol col="3" xl="3">
            <CCard>
              <CCardHeader>
                Estadísticas
              </CCardHeader>
              <CCardBody>
                <CChartPie
                  v-if="estadisticas && estadisticas.gender && estadisticas.gender.values.length > 0"
                  :datasets="[{
                    backgroundColor: [
                      '#41B883',
                      '#d0282e',
                      '#00D8FF',
                      '#DD1B16'
                    ],
                    data: estadisticas.gender.values
                  }]"
                  :labels="estadisticas.gender.keys"
                />
                <CChartLine
                  class="mt-5"
                  v-if="estadisticas && estadisticas.age && estadisticas.age.values.length > 0"
                  :datasets="[{
                    label: 'Edad',
                    backgroundColor: ['#d0282e'],
                    data: estadisticas.age.values
                  }]"
                  :labels="estadisticas.age.keys"
                  :options="{ 
                    scales: {
                      yAxes: [{
                        ticks: {
                          stepSize: 1
                        }
                      }]
                    }
                  }"
                />
              </CCardBody>
            </CCard>
          </CCol> -->
        </CRow>
      </transition>
    </CCol>
    <CreateUser :modalCreateUser="modalCreateUser" :closeModal="() => closeModal()"/>

    <EditUser :getUsers="getUsers" :userUpdate="updatingUser" :modalEditUser="modalEditUser" :closeModalEdit="() => closeModalEdit()"/>
<!--     Eliminar Usuario -->
    <CModal
      style="max-width: 500px !important"
      centered
      title="Eliminar Step"
      color="warning"
      :show.sync="deleteModal"
      size="sm"
    >
      ¿Seguro que quieres eliminar el usuario
      <span style="font-weight: 800">{{currentUser?.email}}?</span>
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="red"
          variant="outline"
          square
          @click="deleteUser()"
        >
          Eliminar
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (deleteModal = !deleteModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

<!--     Notificación -->
    <CModal
      style="max-width: 500px !important"
      centered
      title="Enviar Notificación"
      color="warning"
      :show.sync="modalNotification"
      size="sm"
    >
    <CCard style="max-height: 70vh; overflow-y: scroll;">
        <CCardBody>
          <CRow>
          <CCol sm="12">
            <CInput label="Título" v-model="notification.title"/>
          </CCol>
          <CCol sm="12">
            <CInput label="Mensaje" v-model="notification.body"/>
          </CCol>
          <CCol sm="12" md="6">
            <CInput @keypress="searchLoader = true" class="input-search" style="margin-bottom:0 !important;" label="Buscar Usuarios" v-model="search"/>
            <CListGroup v-if="!searchLoader" style="overflow-y: scroll;height: 186px;">
              <CListGroupItem @click="togglerUsersSelected(user.id)" :color="selectedUsers.includes(user.id) ? 'dark' : null" href="#" v-for="user in optionsUserNotification" :key="user.id">
                <p style="margin-bottom: 0;">{{user?.profile?.name}} {{user?.profile?.lastname}} ({{user?.email}})</p>
              </CListGroupItem>
              <CListGroupItem v-if="noresults">
                <p>No se encontraron resultados</p>
              </CListGroupItem>
            </CListGroup>
            <div style="padding: 12px; height: 186px;" v-else>
              <div class="loader"></div>
            </div>
          </CCol>
          <CCol sm="12" md="6">
            <div style="overflow-y: scroll;height: 220px;">
              <div v-for="user in userNotificationList" :key="user?.value" style="display:inline-block; margin: 4px; margin-top:12px;">
                <span class="c-multi-select-tag" style="background-color: #ebedef; padding:0.5rem; border-radius: 5px;" v-if="user" >{{user?.label}} <span style="cursor: pointer;" @click="togglerUsersSelected(user.id)">{{user.email}} X</span></span>
              </div>
            </div>
          </CCol>
        </CRow>
        </CCardBody>
      </CCard>
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="success"
          variant="outline"
          square
          @click="sendNotification"
          :disabled="!notification.title || !notification.body || !selectedUsers.length"
        >
          Enviar Notificación
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (modalNotification = !modalNotification)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!-- Export Excel -->

        <CModal
      style="max-width: 500px !important"
      centered
      title="Descargar Usuarios"
      color="warning"
      :show.sync="modalExport"
      size="sm"
      class="modal-delete"
    >
    <CCard>
        <CCardBody style="padding: 0;">
          <CRow style="padding: 20px;">
                 <CCol col="12" md="4" >
                    <div style="display: flex; align-items: center;">
                      <label style="margin-bottom: 0; font-weight: 600"
                        >Desde:
                      </label>
                      <datetime
                        width="100%"
                        class="my-2 mx-4 datepickers__datepicker"
                        type="date"
                        v-model="start_date"
                        :format="format" 
                        :parse-date="parseDate" 
                        :format-date="formatDate"
                        :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                      ></datetime>
                    </div>
                    <div style="display: flex; align-items: center;">
                      <label style="margin-bottom: 0; font-weight: 600"
                        >Hasta:</label
                      >
                      <datetime
                        width="100%"
                        class="my-2 mx-4 datepickers__datepicker"
                        type="date"
                        v-model="end_date"
                        :format="format" 
                        :parse-date="parseDate" 
                        :format-date="formatDate"
                        :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                      ></datetime>
                    </div>

                    <CButton color="primary" @click="exportExcel">
                      Descargar
                    </CButton>
                  </CCol>
                  </CRow>
        </CCardBody>
      </CCard>
      <div slot="footer" class="w-100">
      </div>
    </CModal>
  </CRow>
</template>

<script>
import CreateUser from './CreateUser'
import EditUser from './EditUser'
import { getUsers, removeUserFromCompany, getUserInfo, searchUserBySearch } from '../../api/users'
import { exportUsers } from '../../api/exports'
import { customPushNotificationToUsers } from '../../api/notifications'
import checkPermissions from '../../helpers/userPermissions'
import { CChartPie, CChartLine } from '@coreui/vue-chartjs'
import moment from "moment";
import _ from 'lodash/lodash'

import * as infermedica from '../../api/infermedica'

export default {
  name: 'Users',
  components: {CreateUser, EditUser, CChartPie, CChartLine},
  data: () => {
    return {
      items: [],
      estadisticas: null,
      fields: [
        { key: 'email', label: 'Correo Electrónico' },
        { key: 'created_at', label: 'Creado' },
        { key: 'role', label: 'Rol' },
        { key: 'companyName', label: 'Empresa' },
        { key: 'devices_count', label: 'Dispositivos' },
        { key: 'actions', label: 'Acciones' },
      ],
      currentPage: 1,
      total: null,
      users_installed_app_count: null,
      users_with_company_count: null,
      pagination: {
        start: 1,
        perPage: 10,
      },
      updatingUser: null,
      loading: true,
      modalCreateUser: false,
      modalEditUser: false,
      deleteModal: false,
      currentUser: {},
      modalNotification: false,
      optionsUserNotification: [],
      search: '',
      selectedUser: null,
      selectedUsers: [],
      allSearchResults:[],
      notification: {
        title: '',
        body: '',
      },
      toasts: [],
      searchLoader: false,
      noresults: false,
      modalExport: false,
      start_date: null,
      end_date: null,
    }
  },
  computed: {
    userNotificationList() {
      const list = [...this.allSearchResults].map(user => {
        if (this.selectedUsers.includes(user.id)) {
          return user
        }
      })
      return list 
    }
  },
  mounted(){
    this.getUsers()
  },
  methods: {
    checkPermissions,
    goToFicha(userID){      
      this.$router.push({ name: 'FichaPaciente', params: {id: userID} })
    },
    closeModal(){
      this.modalCreateUser = false
      this.getUsers()
    },
    closeModalEdit(){
      this.modalEditUser = false
      this.getUsers()
    },
    openModalCreateUser() {
      this.modalCreateUser = true
    },
    updateUser(user, e){
      e.stopPropagation()
      const data = {user_id: user.id}
      getUserInfo(data).then(res => {
        const { user } = res
        this.updatingUser = {
          user_id: user.profile.user_id,
          name: user.profile.name,
          lastname: user.profile.lastname,
          email: user.email,
          address: user.profile.address,
          phone: user.profile.phone,
          rut: user.profile.rut
          }
      })
      this.modalEditUser = true
      this.modalCreateUser = false
      this.deleteModal = false

    },
    openDeleteModal(user, e) {
      e.stopPropagation()
      this.currentUser = user

      this.deleteModal = true
      this.modalEditUser = false
      this.modalCreateUser = false
    },
    deleteUser(){
      const self = this
      removeUserFromCompany({user_id: this.currentUser.id})
        .then(function(resp){
          self.deleteModal = false
          self.getUsers()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    searchUsers() {
      if (this.search.length > 2) {
        const data = {search: this.search};
      searchUserBySearch(data).then(res => {
        this.optionsUserNotification = res.users
        const allIds = this.allSearchResults.map(user => user.id)

        res.users.forEach(user => {
          if (!allIds.includes(user.id)) {
            this.allSearchResults.push({...user})
          }
        })
        this.searchLoader = false
        this.noresults = !res.users.length ? true : false
      })
      } else {
        this.searchLoader = false
      }
    },
    sendNotification() {
      const data = {...this.notification, user_ids: this.selectedUsers}
      customPushNotificationToUsers(data).then(res => {
        this.toasts.push({
          title: 'Notificación enviada',
          message: `Se envió la notificación a ${res.sent} dispositivos`
            })
          this.modalNotification = false;
          this.selectedUser = null
          this.selectedUsers = []
          this.notification = {
            title: '',
            body: ''
          }
      }).catch(e => {
        console.log(e);
        this.toasts.push({
          title: 'Error',
          message: ''
        })
      })
    },
    togglerUsersSelected(id) {
      if (this.selectedUsers.includes(id)) {
        this.selectedUsers = this.selectedUsers.filter(userid => userid !== id)
        this.optionsUserNotification = this.optionsUserNotification.map(user => {
          return {...user, disabled: this.selectedUsers.includes(user.id)}
        })
      } else {
        this.selectedUsers.push(id)
        this.optionsUserNotification = this.optionsUserNotification.map(user => {
          return {...user, disabled: this.selectedUsers.includes(user.id)}
        })
      }
    },
    getUsers(){
      const data = {
        limit: this.pagination.perPage,
        start: this.pagination.start
      }
      const self = this
      this.loading = true
      getUsers(data)
        .then(function(resp){
          const arr = [...resp.users]
          arr.forEach( user => user.created_at = moment(user.created_at).format("DD-MM-YYYY"))
          arr.forEach(user => user.companyName = user.company?.name ? user.company?.name : 'Sin Empresa')
          arr.forEach(user => user.devices_count = user.devices_count ? user.devices_count : 'Sin Dispositivos')
          self.items = arr

          self.total = resp.users_count
          self.users_installed_app_count = resp.users_installed_app_count
          self.users_with_company_count = resp.users_with_company_count

          self.estadisticas = resp.user_stadistics
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    exportExcel() {
      const data = {
        start_date: moment(this.start_date).format("DD-MM-YYYY"),
        end_date: moment(this.end_date).format("DD-MM-YYYY"),
      };

      data.start_date == "Invalid date" && delete data.start_date;
      data.end_date == "Invalid date" && delete data.end_date;

      exportUsers(data)
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  watch: {
    currentPage: function (start) {
      this.pagination = {
        start,
        perPage: this.pagination.perPage,
      };
      this.getUsers()
    },
    search: _.debounce(function (value) {
      this.searchUsers()
    }, 1000)
  },
}
</script>


<style scoped>
.loader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #fcee28 96%,#97440000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #cedf3a 96%,#aa310100) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
</style>