import {endpoints} from './endpoints'
import request from '../helpers/request'

export function exportComplaints(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.exportComplaints,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function exportVideoRecordings(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.exportVideoRecordings,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function exportUsers(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.exportUsers,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}