<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Crear Usuario"
      size="lg"
      color="primary"
      class="edit-user-modal"
      :show.sync="modalCreateUser"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h5>Crear Usuario</h5>
        <button style="background-color: transparent;  border:none;" @click="closeModal">X</button>
    	</template>
      <CCard
        style="max-height: 70vh; overflow-y: scroll;">
        <CCardBody>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="3" class="c-bg-success c-py-3">
              <img
                style="width: 100%; height: auto;"
                class="c-mb-2"
              />
              <div class="icon-user"><font-awesome-icon icon="user"/></div>
            </CCol>
            <CCol lg="9" class="c-bg-success c-py-3">
              <CForm>
                <CInput
                  placeholder="Nombre"
                  v-model='userUpdate.name'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Apellido"
                  v-model='userUpdate.lastname'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  :class="showAlertEmail ? 'mb-0' : null"
                  placeholder="Email"
                  v-model='userUpdate.email'
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <div v-show="showAlertEmail" class="text-right mb-1 text-primary" style="font-size: 10px;">
                  Ten en cuenta que al modificar el email, el usuario deberá ingresar con el email que ingreses.
                </div>
                <CInput
                  placeholder="Dirección"
                  v-model='userUpdate.address'
                >
                  <template #prepend-content><font-awesome-icon icon="map-marked-alt" /></template>
                </CInput>
                <CInput
                type="number"
                  placeholder="Teléfono"
                  v-model='userUpdate.phone'
                >
                  <template #prepend-content><font-awesome-icon icon="phone" /></template>
                </CInput>
                <CInput
                type="password"
                  placeholder="Contraseña"
                  v-model='userUpdate.password'
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
              </CForm>
            </CCol>
          </CRow>
<!--           <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="12" class="c-bg-success c-py-3">
              <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                <p class="my-0">Prioridades</p>
                <CButton
                  color="secondary"
                  size="sm"
                  class="m-1"
                  @click="addPrioridadesArray()"
                >
                  <font-awesome-icon icon="plus" />
                </CButton>
              </div>
              <div class="d-flex border-bottom py-3 justify-content-center align-items-center w-100" v-for="(prioridad, index) in userUpdate.prioridades">
                <div class="d-flex flex-fill flex-column">
                  <CRow class="d-flex justify-content-center align-items-end">
                    <CCol lg="5" class="c-bg-success c-py-3">
                      <p class="mb-0">Tipo prioridad</p>
                      <v-select :options="tipoPrioridades" label="name" :reduce="data => data.id" v-model="prioridad.type"></v-select>
                    </CCol>
                    <CCol lg="7" class="c-bg-success c-py-3">
                      <p class="mb-0">Descripción</p>
                      <CInput
                        class="d-flex flex-fill mx-1 my-0"
                        placeholder="Descripción"
                        v-model='prioridad.description'
                      >
                        <template #prepend-content><font-awesome-icon icon="list-ol" /></template>
                      </CInput>
                    </CCol>
                    <CCol lg="5" class="c-bg-success c-py-3 mt-1">
                      <p class="mb-0">Fecha inicio</p>
                      <CInput
                        type="date"
                        placeholder="Fecha inicio"
                        v-model='prioridad.start'
                      >
                        <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                      </CInput>
                    </CCol>
                    <CCol lg="7" class="c-bg-success c-py-3 mt-1">
                      <p class="mb-0">Fecha fin</p>
                      <CInput
                        type="date"
                        placeholder="Fecha fin"
                        v-model='prioridad.end'
                      >
                        <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                      </CInput>
                    </CCol>
                  </CRow>
                </div>
                <CButton
                  color="primary"
                  size="sm"
                  class="mx-1 my-0"
                  @click="userUpdate.prioridades.splice(index)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </div>
            </CCol>
          </CRow> -->
        </CCardBody>
      </CCard>
    	<template #footer>
	      <CButton
	        color="primary"
	        size="sm"
	        class="m-1"
	        @click="closeModal"
	      >
	        Cancelar
	      </CButton>
	      <CButton
          :disabled="disableBtn"
	        color="success"
	        class="m-1"
	        @click="create()"
	      >
	        Crear
	      </CButton>
    	</template>
    </CModal>
  </div>
</template>

<script>
import { createUser } from '../../api/users'
/* import { validate, clean, format } from 'rut.js' */

export default {
  name: 'createUser',
  props: ['modalCreateUser', 'closeModal'],
  data: () => {
    return {
      userData: null,
      fieldsGroups: [
        { key: 'name', label: 'Usuario' },
        { key: 'email', label: 'Email' },
      ],
      showAlertEmail: false,
    	userUpdate: {
    		name: '',
    		lastname: '',
    		email: '',
    		address: '',
    		phone: '',
    		password: '',
    	},
        toasts: [],
    }
  },
  computed: {
    disableBtn() {
      if (!this.userUpdate.name || !this.userUpdate.lastname || !this.userUpdate.email || !this.userUpdate.address || !this.userUpdate.phone || !this.userUpdate.password) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
/*     changedRut(){
      console.log(validate(this.userUpdate.rut))
    }, */
    changedEmail(){
      if(this.user.email !== this.userUpdate.email){
        this.showAlertEmail = true
      }
      else{
        this.showAlertEmail = false
      }
    },
    create(){
      const self = this
      createUser(this.userUpdate)
        .then(function(resp){
        self.toasts.push({
              title: '',
              message: 'Usuario creado correctamente'
            })
            self.closeModal()
        })
        .catch(function(error){
                error.response.data.forEach(msg => {
                    if (msg == 'Ese email ya está en uso, por favor verifica.') {
                        self.toasts.push({
                        title: 'Error',
                        message: 'El correo ya está registrado, seleccione la región correspondiente desde la App Móvil'
                    })  
                    } else {
                        self.toasts.push({
                        title: 'Error',
                        message: msg
            })
            }
                })
        })
    }
  }
}
</script>